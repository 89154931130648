/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import CookieBanner from "./common/CookieBanner"
import { Tracking } from "./layout/misc/Tracking"
import { useTranslation } from "react-i18next"

function SEO({ description, lang, meta, title, isHome }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            image
          }
        }
      }
    `
  )
  const { t } = useTranslation()

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const defaultImage = site.siteMetadata?.image
  const siteUrl = site.siteMetadata?.url
  const titleTemplate = useMemo(() => {
    if (isHome) {
      return('DICE. - ' + t("Hero-Line") + ' | hae.sh')
    }
    if (defaultTitle) {
      switch(title) {
        case 'Solution': return(t('Solution-Helmet') + ` | ${defaultTitle}`)
        case 'Lösung': return(t('Solution-Helmet') + ` | ${defaultTitle}`)
        case 'For Companies': return(t('Companies-Helmet') + ` | ${defaultTitle}`)
        case 'Für Unternehmen': return(t('Companies-Helmet') + ` | ${defaultTitle}`)
        default: return(`%s | ${defaultTitle}`)
      }
    }

    return null
  }, [t, isHome, defaultTitle, title])
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate = {titleTemplate}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${siteUrl}${defaultImage}`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Mulish:wght@400..800&amp;display=fallback" rel="stylesheet" />
        <style>
          {`html {
        scroll-behavior: smooth;
      }`}
        </style>
      </Helmet>
      <Tracking />

      <CookieBanner />
    </>
  )
}

SEO.defaultProps = {
  lang: "de",
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
