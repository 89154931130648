import React from "react"

interface ILogoProps {
  logoColor?: string
  dotColor?: string
  height?: string
  width?: string
  className?: string
}

export const HaeshLogoSmall = ({
  logoColor = "#ffffff",
  dotColor = "#ff4500",
  height = "40",
  width = "115",
  className,
}: ILogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49.2 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <style>{".st2{fill:#fff}"}</style>
    <path
      d="M34.5 45.9v-6.4h6.4v6.4h-6.4z"
      style={{
        fill: "#ff4500",
      }}
    />
    <path
      d="M7 47.4 12.4 35H8.2l2.3-5.2h4.3l2.3-5.3h-4.3l2.2-5.2h4.3l5.2-11.8h6.7L26 19.3h4.1l5.2-11.8H42l-5.2 11.8h4l-2.2 5.2h-4.1l-2.3 5.3h4L34.1 35h-4l-5.4 12.5h-6.8L23.3 35h-4.1l-5.4 12.5H7zm14.5-17.7h4.1l2.3-5.3h-4.1l-2.3 5.3z"
      style={{
        fill: "#222b45",
      }}
    />
  </svg>
  )
}
