import React, { useMemo } from "react"
import { Popover, Transition } from "@headlessui/react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { HaeshLogo } from "../common/HaeshLogo"
import { getNavigation } from "../landing/_nav"
import "./hack.css"
import {
  ChevronRightIcon,
  ChevronUpIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline"
import MobileSubMenu from "./MobileSubMenu"

const Header = ({ isHome, fixed }) => {

  const { t } = useTranslation()

  const links = {
    "resources": t("Ressources-Link"),
    "cicd": t("CI/CD Integrity-Link"),
    "fraud": t("Fraud Detection-Link")
  }

  const navItems = getNavigation(links)

  return (
    <Popover
      as="header"
      className={`${
        fixed ? "fixed top-0 w-full" : "sticky top-0"
      } z-50 bg-white tinted-shadow shadow`}
    >
      {({ open, close }) => (
        <>
          <div className="py-4 base-container">
            <nav
              className={`mx-2 md:mx-4 lg:mx-0 transition-all duration-300 relative flex items-center justify-between border-b-1 border-gray-50`}
              aria-label="Global"
            >
              <div className="flex items-center justify-between flex-1">
                <div className="flex items-center justify-between w-full lg:w-auto">
                  <Link to="/" className="">
                    <span className="sr-only">hae.sh</span>
                    <HaeshLogo
                      dotColor={"#ff4500"}
                      logoColor="#222B45"
                      className="py-1 md:py-0"
                    />
                  </Link>
                  <div className="flex items-center lg:hidden">
                    <Popover.Button className="inline-flex items-center justify-center p-2 bg-transparent rounded-sm text-slate-800 hover:text-slate-600 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-slate-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="w-6 h-6" aria-hidden="true" />
                      )}
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-8 lg:space-x-12 lg:flex lg:ml-10">
                  {navItems.map(item =>
                    item.subMenu ? (
                      <Popover className="relative">
                        {({ open, close }) => (
                          <>
                            <Popover.Button
                              className={`${
                                open ? "text-slate-600" : "text-slate-800"
                              } group bg-white rounded-md inline-flex items-center
                            text-base font-bold hover:text-slate-600 focus:outline-none `}
                            >
                              <Trans>{item.name}</Trans>

                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? "text-slate-600"
                                    : "text-slate-800 rotate-180"
                                } ml-2 h-5 w-5 transition-all group-hover:text-slate-600`}
                                aria-hidden="true"
                              />
                            </Popover.Button>

                            <Transition
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                  {item.subMenu && (
                                    <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                                      {item.subMenu.map(itemSub => (
                                        <Link
                                          key={itemSub.name}
                                          onClick={() => close()}
                                          to={itemSub.href}
                                          className="flex items-start p-3 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-slate-50"
                                        >
                                          <itemSub.icon
                                            className="flex-shrink-0 w-6 h-6 text-primary"
                                            aria-hidden="true"
                                          />
                                          <div className="ml-4">
                                            <p className="text-base font-bold text-slate-800">
                                              <Trans>{itemSub.name}</Trans>
                                            </p>
                                          </div>
                                        </Link>
                                      ))}
                                    </div>
                                  )}
                                  {item.callsToAction && (
                                    <div className="px-5 py-5 bg-slate-50 sm:px-8">
                                      {item.callsToAction.map(itemCTA => (
                                        <div
                                          key={itemCTA.name}
                                          className="flow-root"
                                        >
                                          <Link
                                            to={itemCTA.href}
                                            className="flex items-center p-3 -m-3 text-base font-bold transition duration-150 ease-in-out rounded-md group text-slate-600 hover:bg-slate-100"
                                          >
                                            <itemCTA.icon
                                              className="flex-shrink-0 w-6 h-6 group-hover:text-primary"
                                              aria-hidden="true"
                                            />
                                            <div className="ml-3">
                                              <Trans>{itemCTA.name}</Trans>
                                            </div>
                                          </Link>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    ) : item.href.startsWith('http') ? (
                      <a
                        href={item.href}
                        className="text-base font-bold text-slate-800 hover:text-slate-600"
                      >
                        <Trans>{item.name}</Trans>
                      </a>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="text-base font-bold text-slate-800 hover:text-slate-600"
                      >
                        <Trans>{item.name}</Trans>
                      </Link>
                    )
                  )}
                </div>
              </div>
              <div className="hidden lg:flex lg:items-center lg:space-x-6">
                <Link to="/contact" className="ml-12 lg:ml-16 btn-solid">
                  <Trans>Request-Demo-Button</Trans>
                </Link>
              </div>
            </nav>
          </div>
          <Transition
            show={open}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="relative inset-x-0 z-50 w-screen transition origin-top lg:hidden"
            >
              <div className="absolute w-full bg-white ring-1 ring-black ring-opacity-5">
                <div className="pt-3 pb-6">
                  <div className="px-2 space-y-1">
                    {navItems.map(item =>
                      item.subMenu ? (
                        <MobileSubMenu
                          itemSubMenu={item}
                          key={item.name}
                          onClose={() => close()}
                          className="block px-3 py-2 space-y-1 text-base font-medium text-center text-gray-900 rounded-md hover:bg-gray-50"
                        />
                      ) : (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="block px-3 py-2 text-base font-medium text-center text-gray-900 rounded-md hover:bg-gray-50"
                        >
                          <div className="flex justify-between font-bold">
                            <Trans>{item.name}</Trans>
                            <ChevronRightIcon className="h-6 text-primary-300" />
                          </div>
                        </Link>
                      )
                    )}
                  </div>
                  <div className="px-2 pt-2"></div>
                  <div className="flex justify-center px-5 mt-6">
                    <Link
                      to="/contact"
                      className="justify-center inline-block mx-auto btn-solid"
                    >
                      <Trans>Request-Demo-Button</Trans>
                    </Link>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default Header
