import React from "react"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"

const BaseLayout = ({
  children,
  isHome,
  fixedHeader,
}: {
  children: React.ReactNode
  isHome?: boolean
  fixedHeader?: boolean
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header isHome={!!isHome} fixed={fixedHeader} />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  )
}

export default BaseLayout
