import React from "react"
import { Disclosure, Transition } from "@headlessui/react"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/outline"

const MobileSubMenu = ({ itemSubMenu, className, onClose }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div className={className ?? ""}>
          <Disclosure.Button
            key={itemSubMenu.name}
            className="flex justify-between w-full font-bold"
          >
            <div>
              <Trans>{itemSubMenu.name}</Trans>
            </div>

            <ChevronUpIcon
              className={`block h-6 text-primary-300 transition-all${
                open ? "" : " rotate-180"
              }`}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static className="space-y-1">
              {itemSubMenu.subMenu.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={() => onClose()}
                  className="block py-1 pl-2 ml-5 text-base font-medium text-center text-gray-900 rounded-md hover:bg-gray-50"
                >
                  <div className="flex justify-between font-semibold">
                    <Trans>{item.name}</Trans>
                    <ChevronRightIcon className="h-6 text-primary-300" />
                  </div>
                </Link>
              ))}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  )
}

export default MobileSubMenu
