import { CogIcon, CurrencyEuroIcon, GlobeAltIcon, HomeIcon, LightBulbIcon, PhoneIcon, ShoppingCartIcon } from "@heroicons/react/outline";

export const getNavigation = (links: Record<string,string>) => {
  return([
  { name: "Solution", href: "/solution" },
  { name: "CI/CD Integrity", href: links['cicd']},
  { name: "Fraud Detection", href: links['fraud']},
  {
    name: "Companies",
    href: "/companies",
    subMenu: [
      {
        name: 'Companies-General-Title',
        description: 'Companies-General-Subtitle',
        href: '/companies#general',
        icon: HomeIcon,
      },
      {
        name: 'Companies-Manufacturing-Title',
        description: 'Companies-Manufacturing-Subtitle',
        href: '/companies#manufacturing',
        icon: CogIcon,
      },
      {
        name: 'Companies-Financials-Title',
        description: 'Companies-Financials-Subtitle',
        href: '/companies#financials',
        icon: CurrencyEuroIcon,
      },
      {
        name: 'Companies-Retail-Title',
        description: 'Companies-Retail-Subtitle',
        href: '/companies#retail',
        icon: ShoppingCartIcon,
      },
      {
        name: 'Companies-Utilities-Title',
        description: 'Companies-Utilities-Subtitle',
        href: '/companies#utilities',
        icon: LightBulbIcon,
      },
      {
        name: 'Companies-Services-Title',
        description: 'Companies-Services-Subtitle',
        href: '/companies#services',
        icon: GlobeAltIcon,
      },
    ],
    callsToAction: [
      { name: 'subMenuCTA', href: '/contact', icon: PhoneIcon },
    ]
  },
  { name: "Ressources", href: links['resources'] },
  { name: "About", href: "/about" }
])}