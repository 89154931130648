import React, { useEffect } from "react"
import { useState } from "react"
import { Trans } from "react-i18next"
import { Link } from "gatsby"
import Cookies from "universal-cookie"

const CookieBanner = () => {
  const [open, setOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [consents, setConsents] = useState({
    Essentiell: true,
    Analytics: true,
    Marketing: true,
  })

  const gdprList = [
    { name: "Essentiell", cookies: [], disabled: true },
    { name: "Analytics", cookies: ["gdpr-google-tagmanager"], disabled: false },
    {
      name: "Marketing",
      cookies: ["gdpr-hotjar"],
      disabled: false,
    },
  ]

  useEffect(() => {
    const cookies = new Cookies()
    let configured = cookies.get("cookies-configured")
    setOpen(!configured)
  }, [])

  const handleSave = () => {
    const cookies = new Cookies()
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 2)

    Object.keys(consents).forEach(c => {
      if (consents[c]) {
        let item = gdprList.find(x => x.name === c)
        item.cookies.map(cook =>
          cookies.set(cook, true, { path: "/", expires: expires })
        )
      } else {
        let item = gdprList.find(x => x.name === c)
        item.cookies.map(cook => cookies.remove(cook, { path: "/" }))
      }
    })
    cookies.set("cookies-configured", true, { path: "/", expires: expires })
    setOpen(false)
  }

  if (!open) return null

  return (
    <>
      <div className="transition-none fixed inset-x-0 bottom-0 z-50">
        <div className="bg-slate-100 shadow-md">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row items-center md:justify-between justify-center flex-wrap">
              <div className="md:w-0 block md:flex-1 md:flex items-center">
                <p className="md:ml-3 md:mr-6 text-center md:text-left font-medium text-black ">
                  {/* <span className="md:hidden">
                    <Trans>Wir verwenden Cookies</Trans>
                  </span> */}
                  <span className="block md:inline whitespace-pre-wrap text-sm">
                    <Trans>
                      Diese Website setzt Cookies, Web Beacons, JavaScript und
                      ähnliche Technologien ein. Detaillierte Informationen dazu
                      und wie Sie Ihre Zustimmung zurückziehen finden Sie in der
                    </Trans>{" "}
                    <Link to="/privacy">
                      <Trans>Datenschutzerklärung</Trans>
                    </Link>
                    <Trans> der Seite.</Trans>
                  </span>
                </p>
                {isEditing && (
                  <div className="md:ml-3 md:mr-9 mt-2 md:mt-0 space-x-5 flex flex-row justify-center">
                    {gdprList.map(item => (
                      <div className="">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          className="block mx-auto h-4 w-4 focus:ring-primary text-primary border-gray-300 rounded"
                          checked={consents[item.name]}
                          onChange={e =>
                            setConsents(prev => ({
                              ...prev,
                              [item.name]: !consents[item.name],
                            }))
                          }
                          disabled={item.disabled}
                        />

                        <label
                          htmlFor="comments"
                          className="block text-center mt-1 text-sm text-secondary"
                        >
                          <Trans>{item.name}</Trans>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="items-center justify-center flex md:block my-auto space-x-6 mt-3 md:my-auto shrink-0 w-full sm:w-auto tracking-wider">
                {!isEditing && (
                  <button
                    className="my-auto ml-6 md:ml-0 text-primary font-bold"
                    onClick={() =>
                      isEditing ? handleSave() : setIsEditing(true)
                    }
                  >
                    <Trans>Anpassen</Trans>
                  </button>
                )}
                <button
                  className="mx-auto my-auto btn-solid"
                  onClick={() => handleSave()}
                >
                  <Trans>
                    {isEditing ? "Auswahl speichern" : "Alle akzeptieren"}
                  </Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CookieBanner
