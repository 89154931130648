import { Switch } from "@headlessui/react"
import { Link } from "gatsby"
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React, { useCallback } from "react"
import { HaeshLogoSmall } from "../common/HaeshLogoSmall"

/* This example requires Tailwind CSS v2.0+ */

const Footer = () => {
  const { t, changeLanguage } = useI18next()
  const { i18n } = useTranslation()

  const isEn = useCallback(() => i18n.language === "en", [i18n.language])

  const navItems = {
    main: [
      { name: t("Contact"), href: "/contact" },
      { name: t("Imprint"), href: "/imprint" },
      { name: t("Privacy"), href: "/privacy" },
      { name: t("LinkedIn"), href: "https://linkedin.com/company/haesh" },
    ],
  }

  return (
    <footer className="z-10 bg-white mt-8 tinted-shadow">
      <div className="max-w-7xl mx-auto pb-4 pt-10 px-4 lg:px-8 grid lg:grid-cols-5">
        <div className="md:flex flex-wrap justify-center space-y-2 md:space-y-0 md:space-x-8 lg:space-x-10 md:order-2 lg:col-span-3 mt-6 lg:mt-0">
          {navItems.main.map(item =>
            item.href.startsWith("https://") ? (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noopener noreferer"
                className="block md:inline text-center text-slate-800 hover:text-slate-700 font-bold"
              >
                {item.name}
              </a>
            ) : (
              <Link
                key={item.name}
                to={item.href}
                className="block md:inline text-center text-slate-800 hover:text-slate-700 font-bold"
              >
                {item.name}
              </Link>
            )
          )}
        </div>
        <div className="flex md:order-2 justify-center space-x-2 mt-8 lg:mt-0">
          <div className={!isEn() ? "font-bold" : "font-normal"}>DE</div>
          <Switch
            checked={isEn()}
            onChange={val => changeLanguage(val ? "en" : "de")}
            className={`${
              isEn() ? "bg-slate-800" : "bg-slate-200"
            } mt-0.5 relative inline-flex flex-shrink-0 h-5 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
        `}
          >
            <span className="sr-only">
              <Trans>Website-Sprache</Trans>
            </span>
            <span
              aria-hidden="true"
              className={`${
                isEn() ? "translate-x-6" : "translate-x-0"
              } pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
            />
          </Switch>
          <div className={isEn() ? "font-bold" : "font-normal"}>EN</div>
        </div>
        <div className="mt-8 md:-mt-3 md:order-1">
          <div className="lg:flex text-center text-base text-gray-400 whitespace-pre-wrap">
            <div className="lg:pr-4 md:mt-1">
              <HaeshLogoSmall
                className="inline"
                logoColor="#222B45"
                width="46"
                height="2.5rem"
              />
            </div>
            <div className="inline text-center lg:text-left md:mt-3">
              <p>&copy; 2021 hae.sh GmbH</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
