import React from "react"

interface ILogoProps {
  logoColor?: string
  dotColor?: string
  height?: number
  width?: number
  className?: string
  svgProps?: React.SVGProps<SVGSVGElement>
}

export const HaeshLogo = ({
  logoColor = "#222b45",
  dotColor = "#ff4500",
  height = 41,
  width = 121,
  svgProps,
}: ILogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M27.901 40.444 45.3.444h16.785v5.242h-8.72v11.711h6.02v5.314h-6.02v12.463h8.72v5.27h-16v-7.17l-7.78-.002-3.09 7.172h-7.313Zm18.16-12.466V14.37l-5.455 13.606 5.455.002ZM97.564 40.449V.447h7.301v16.956h8.2V.447h7.401v40.002h-7.401V22.715h-8.2V40.45h-7.3ZM87.682 19.445l-4.9-4.3a18.402 18.402 0 0 1-2.4-2.6 5.785 5.785 0 0 1-.8-3.3c-.027-.9.256-1.783.8-2.5a3.105 3.105 0 0 1 2.5-1.1 3.199 3.199 0 0 1 2.3.8 4.15 4.15 0 0 1 1.1 2.3c.156.69.257 1.393.3 2.1h6.2a15.882 15.882 0 0 0-.7-3.9 11.856 11.856 0 0 0-1.6-3.4 6.631 6.631 0 0 0-2.9-2.3 11.868 11.868 0 0 0-4.6-.8c-3.2 0-5.6.8-7.4 2.5a9.195 9.195 0 0 0-2.7 7.1 10.795 10.795 0 0 0 1.2 5.4 18.081 18.081 0 0 0 3.4 4l5 4.3a18.759 18.759 0 0 1 3.1 3.3 6.96 6.96 0 0 1 1.2 4.2 5.38 5.38 0 0 1-.7 2.7 2.798 2.798 0 0 1-2.5 1 3.486 3.486 0 0 1-2.5-.9 6.27 6.27 0 0 1-1.4-2.7c-.245-.88-.38-1.787-.4-2.7h-6.3c.043 2.204.519 4.379 1.4 6.4a9.001 9.001 0 0 0 3.6 4.1 11.97 11.97 0 0 0 5.9 1.3 10.792 10.792 0 0 0 5.2-1.2 8.555 8.555 0 0 0 3.4-3.4 10.037 10.037 0 0 0 1.3-5c.06-2.284-.53-4.538-1.7-6.5a30.008 30.008 0 0 0-4.4-4.9Z"
        fill={logoColor}
      />
      <path d="M63.643 23.28V16.9h6.378v6.378h-6.378Z" fill={dotColor} />
      <path
        d="m.884 40.445 5.384-12.483H1.996l2.294-5.25h4.262l2.305-5.3H6.59l2.244-5.157h4.28L18.282.445h6.746l-5.179 11.809h4.145l5.17-11.81h6.746l-5.167 11.811h4.045l-2.245 5.157h-4.054l-2.303 5.3h4.047l-2.286 5.25h-4.043L18.51 40.445h-6.752l5.403-12.483h-4.136L7.63 40.445H.884Zm14.423-17.733h4.133l2.31-5.3h-4.147l-2.296 5.3Z"
        fill={logoColor}
      />
    </svg>
  )
}
