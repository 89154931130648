import React from "react"
import { Helmet } from "react-helmet"

export const Tracking = () => {
  return (
    <Helmet>
      {/* <script type="application/javascript">
        {`function salessuckers_optIn(callback) {
    var cookies = document.cookie.split(";").map(function (entry) {
      var cookie = entry.trim().split("=")
      if (cookie && cookie.length == 2) {
        return { name: cookie[0], value: cookie[1] }
      }
    })
    for (var i = 0, len = cookies.length; i < len; i++) {
      if (cookies[i].name == "gdpr-sales-suckers") {
        callback(true)
        return
      }
    }
  }
salessuckers_cid=127; salessuckers_did=198;
var salessuckers_d='https://wunderpus.azurewebsites.net/client.js';
(function(d,t) { var s=d.createElement(t); var r=d.getElementsByTagName(t)[0];
    s.src=salessuckers_d;
    s.setAttribute('async', '');
    r.parentNode.insertBefore(s,r)
}(document,'script'));`}
      </script>
      <noscript>{`
      <img src="https://wunderpus.azurewebsites.net/api/v1/client/tr_px/127/198" style="width:0px;height:0px;" alt="" />
    `}</noscript> */}
      {/* <script type="application/javascript">{`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2547021,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}</script>
        <script type="application/javascript" src="https://secure.gift2pair.com/js/211727.js" ></script> 
        <noscript>{`<img alt="" src=https://secure.gift2pair.com/211727.png style="display:none;" />`}</noscript> */}
    </Helmet>
  )
}
